<template>
  <page-wrapper :no-access="!haveAccess" :loading="!documentsListMeta.typeName">
    <v-row>
      <v-col>
        <v-toolbar elevation="0">
          <h2>{{ documentsListMeta.typeName }}</h2>
          <v-spacer />
          <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="поиск"
              class="mt-7 expanding-search mr-3"
              :class="{'search-closed' : searchClosed && !search}"
              @focus="searchClosed = false"
              @blur="searchClosed = true"
              clearable></v-text-field>
          <router-link :to="createNewLink" v-slot="{href}" custom class="mx-3" v-if="user && user.isAdmin">
            <a :href="href" style="color: #000 !important; text-decoration:none;"><v-icon>mdi-plus-circle-outline</v-icon></a>
          </router-link>
          <v-btn icon @click.stop="expandSetting = !expandSetting">
            <v-icon>{{ expandSetting ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-divider />
    <v-expand-transition>
      <v-row class="row-margin" v-show="expandSetting">
        <v-col class="mt-2">
          <v-row justify="start" align="center">
            <v-col
                cols="2"
            >
              <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="currentFilter.startDate"
                      label="С"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="currentFilter.startDate"
                    @input="startDateMenu = false; fetchList()"
                    :max="currentFilter.endDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
                cols="2"
            >
              <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="currentFilter.endDate"
                      label="По"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="currentFilter.endDate"
                    @input="endDateMenu = false; fetchList()"
                    :min="currentFilter.startDate"
                    :max="dayNow"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
                cols="auto"
            >
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                      color="primary"
                      dark
                      v-on="on"
                      icon
                  >
                    <v-icon>mdi-apps</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in menuItems"
                      :key="index"
                      @click="computeDates(item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col
                cols="2"
            >
              <v-select
                  v-model="currentFilter.activeType"
                  :items="activeTypes"
                  solo
                  dense
                  class="mb-n6"
                  @change="fetchList"
              ></v-select>
            </v-col>
            <v-col
                cols="auto"
            >
              <v-dialog
                  v-model="fieldsSelector"
                  max-width="600px"
                  @click:outside="fieldsSelectorClosed"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Поля
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Поля</span>
                  </v-card-title>
                  <v-container style="max-height: 800px" class="overflow-y-auto " >
                    <v-row>
                      <v-col class="fields-selector-col">
                        <v-text-field
                            v-model="fieldsSearch"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="поиск"
                            class="mt-0 pt-0"
                        ></v-text-field>
                        <draggable v-model="fieldsSelectorItems" ghost-class="ghost">
                          <transition-group type="transition" name="fields-selector" class="text-left">
                            <v-list-item class="sortable" v-for="field in fieldsSelectorItems" :key="field.value">
                              <v-list-item-action>
                                <v-checkbox v-model="field.checked"/>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <text-highlight :queries="fieldsSearch">{{field.text}}</text-highlight>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </transition-group>
                        </draggable>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="sortFieldsByChecked"
                    >
                      сортировать
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="resetFields"
                    >
                      сбросить
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="fieldsSelectorClosed"
                    >
                      ОК
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="auto" class="pl-0">
              <v-btn icon large @click="fetchList">
                <v-icon>mdi-update</v-icon>
              </v-btn>
              <v-btn icon large @click="downloadList">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
              <router-link :to="configurationLink" v-slot="{href}" custom class="mx-3" v-if="user && user.isAdmin">
                <a :href="href" style="color: #000 !important; text-decoration:none;"><v-icon>mdi-cog</v-icon></a>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-row class="row-margin">
      <v-col cols="12">
        <v-data-table
            :headers="tableHeaders"
            :items="documentsList"
            item-key="id"
            :footer-props="{
                    itemsPerPageOptions: [50, 100, 200],
                    itemsPerPageText: 'Отображать по'
                  }"
            class="elevation-2"
            :calculate-widths="true"
            :loading="documentsListLoading"
            :search="search">
          <template v-slot:item.link="{ item }">
            <router-link :to="documentLink(item)" v-slot="{href}" custom>
              <a :href="href" style="color: #000 !important; text-decoration:none;"><v-icon>mdi-call-made</v-icon></a>
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <a id="download-link" v-show="false"></a>
  </page-wrapper>
</template>

<script>
import moment from 'moment-timezone';
import Draggable from 'vuedraggable';
import {mapState, mapGetters, mapActions} from "vuex";
import {documentsListDownload} from "../api/document";
import TextHighlight from 'vue-text-highlight';
import PageWrapper from "../components/PageWrapper";

export default {
  name: "DocumentsList",
  data: function () {
    return {
      expandSetting: true,
      startDateMenu: false,
      endDateMenu: false,
      menuItems: ['Диапазон дат', 'За текущий день', 'За текущую неделю'],
      activeTypes: [
        {text: 'Все документы', value: 'all'},
        {text: 'Активные', value: 'active'},
        {text: 'Архивные', value: 'archive'},
      ],
      fieldsSelector: false,
      fieldsSelectorItems: [],
      fieldsSearch: '',
      searchClosed: true,
      search: '',
    }
  },
  watch: {
    $route(to) {
      this.init(to)
    },
    pageName() {
      if (this.pageName !== '') {
        document.title = this.pageName
      }
    },
  },
  computed: {
    ...mapState('document', [
        'documentsListMeta',
        'currentFilter',
        'documentsListLoading',
        'documentsList',
        'haveAccess'
    ]),
    ...mapGetters('document', [
        'documentsListAvailableFields'
    ]),
    pageName() {
      return this.documentsListMeta.typeName;
    },
    configurationLink() {
      return '/documents/'+this.$route.params.typeID+'/configuration'
    },
    createNewLink() {
      return '/documents/'+this.$route.params.typeID+'/create'
    },
    tableHeaders() {
      let headers = this.currentFilter.fields.map(e => {
        let a = this.documentsListMeta.attrs.find(at => at.key === e)
        if (!a) {
          return null
        }
        return {
          text: a.name,
          value: a.key,
        }
      }).filter(e => e !== null)
      if (headers.length > 0) {
        headers.unshift({
          text: '',
          value: 'link',
          width: '50px',
          sortable: false,
          align: 'center',
        })
      }
      return headers;
    },
    user() {
      return this.$store.getters.getUser;
    },
    dayNow() {
      return  moment().format('YYYY-MM-DD')
    },
  },
  methods: {
    ...mapActions('document', ['fetchDocumentsListMeta', 'setFilterAndFetch']),
    fieldsSelectorClosed() {
      this.fieldsSelector = false
      this.currentFilter.fields = this.fieldsSelectorItems.filter(e => e.checked).map(e => e.value)
      if (this.currentFilter.fields.length === 0) {
        this.currentFilter.fields = ['c_10012']
      }
      this.setFilterAndFetch(this.currentFilter)
    },
    fetchList() {
      this.setFilterAndFetch(this.currentFilter)
    },
    init(r) {
      this.fetchDocumentsListMeta(r.params.typeID)
        .then(() => {
          this.resetFields()
          this.fieldsSelectorItems.forEach((val, index) => {
            if (this.currentFilter.fields.includes(val.value)) {
              this.fieldsSelectorItems[index].checked = true
            }
          })
          this.sortFieldsByChecked()
        })
    },
    resetFields() {
      this.fieldsSelectorItems = this.documentsListAvailableFields.slice()
      this.fieldsSelectorItems.forEach((val, index) => {
        this.fieldsSelectorItems[index].checked = false
      })
    },
    sortFieldsByChecked() {
      this.fieldsSelectorItems = this.fieldsSelectorItems.sort((a, b) => {
        return (a.checked ? -1 : 0) + (b.checked ? 1 : 0)
      })
    },
    computeDates(p) {
      if (p === 'За текущий день') {
        this.currentFilter.startDate = moment().format('YYYY-MM-DD')
        this.currentFilter.endDate = moment().format('YYYY-MM-DD')
      } else if (p === 'За текущую неделю') {
        this.currentFilter.startDate = moment().startOf('isoWeek').format('YYYY-MM-DD')
        this.currentFilter.endDate = moment().format('YYYY-MM-DD')
      }
      this.fetchList()
    },
    documentLink(item) {
      if (!item.id) {
        return '/documents/'+this.$route.params.typeID+'/list'
      }
      return '/documents/'+this.$route.params.typeID+'/'+item.id + '/main'
    },
    downloadList() {
      documentsListDownload(this.currentFilter, this.$store.getters.getAuthToken)
      .then(r => {
        if (r.success) {
          let fileUrl = window.URL.createObjectURL(new Blob([r.body]))
          let fileLink = document.getElementById('download-link')
          fileLink.href = fileUrl

          fileLink.setAttribute('download', 'Выгрузка.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        }
      })
    },
    highlightFiledNameForSearch(name) {
      name.replace
    }
  },
  mounted() {
    this.init(this.$route)
  },
  components: {
    'draggable': Draggable,
    'TextHighlight': TextHighlight,
    'PageWrapper': PageWrapper,
  }
}
</script>

<style lang="scss">

.row-margin {
  margin-left: 3px;
  margin-right: 3px;
}

.sortable {
  background-color: white;
  width: 100%;
  padding: 0.5em;
  cursor: move;
  margin-bottom: 2px;
}

.fields-selector-col {
  background: rgb(231, 231, 231);
}

.fields-selector-col .sortable-drag {
  opacity: 0;
}

.fields-selector-move {
  transition: transform 0.1s;
}

.ghost {
  border-left: 6px solid #1976d2;
  box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
  opacity: 0.7;
}

.expanding-search {
  transition: max-width 0.3s;
  max-width: 300px;
}

.expanding-search .v-input__slot  {
  &::before {
    border-color: transparent !important;
  }
  &::after {
    border-color: transparent !important;
  }
}

.search-closed {
  max-width: 25px;
  .v-input__slot {
    cursor: pointer !important;
    background: transparent !important;
  }
}

.highlight-text {
  background-color: gold;
}

table {
  table-layout:fixed;
}
</style>